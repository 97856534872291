import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
function NotFoundAstronaut(props) {
  let { svg } = props
  console.log(svg)
  return (
    <SuccessDiv className="col-12 justify-content-center text-center align-content-center col-md-12 col-lg-6 ">
      <ChooseImg
        positions={["20%", "0px", "10px", "75%"]}
        alt={svg[2].title}
        src={svg[2].file.url}
        satellite
      />
      <BlackDiv>
        <BlackHole></BlackHole>
      </BlackDiv>
      <AstronautThree alt={svg[0].title} fluid={svg[0].fluid} />
    </SuccessDiv>
  )
}

const SuccessDiv = styled.div`
  display: relative;
  /* background: red; */
  @media (max-width: 640px) {
    height: auto;
    width: 100vw;
  }

  @media (min-width: 641px) {
    height: 50vh;
    width: 100vw;
    top: 100px;
  }

  @media (min-width: 1024px) {
    min-height: auto;
    width: 100%;
    height: 100%;
    margin-top: -100px;
  }
`

const AstronautThree = styled(Img)`
  display: block;
  position: absolute;

  animation: astronaut 30s linear infinite;
  transition: all 300ms;

  @media (max-width: 640px) {
    width: 220px;
    left: 10px;
    top: -30px;
  }

  @media (min-width: 641px) {
    top: -120px;
    width: 350px;
    left: 20px;
  }

  @media (min-width: 1024px) {
    top: -50px;
    width: 350px;
  }

  @keyframes astronaut {
    0% {
      transform: translateY(0px) rotate(-20deg) translateX(0px);
    }
    50% {
      transform: translateY(25px) rotate(-10deg) translateX(-5px);
    }
    100% {
      transform: translateY(0px) rotate(-20deg) translateX(0px);
    }
  }
`

const ChooseImg = styled.img`
  display: block;
  position: absolute;
  width: 7%;
  transition: all 300ms;
  margin: ${props => props.margin};
  z-index: 2;

  animation: sati 10s cubic-bezier(0.1, 0, 0, 0) infinite;

  @keyframes sati {
    0% {
      transform: skew(-0.06turn) rotate(0deg);
    }

    100% {
      transform: skew(-0.09turn) rotate(360deg);
    }
  }

  ${({ satellite }) =>
    satellite &&
    `

    @media (max-width: 640px) {
  top: 135px;
  left: 270px;
  width:30px

      
}


@media (min-width: 641px) {
top: 175px;
  left: 550px;

  width:40px
}

@media (min-width: 1024px) {
  top: 165px;
  left: 550px;

  width:35px
}

 `}
`

const BlackDiv = styled.div`
  position: absolute;

  z-index: 0;
  background: transparent;
  border-radius: 50%;
  box-shadow: 0px 0px 25px 20px rgba(0, 0, 0, 1);

  @media (max-width: 640px) {
    width: 80px;
    height: 80px;
    top: 120px;
    left: 260px;
  }

  @media (min-width: 641px) {
    width: 180px;
    height: 180px;
    top: 90px;
    left: 480px;
  }

  @media (min-width: 1024px) {
    width: 180px;
    height: 180px;
    top: 80px;
    left: 480px;
  }

  animation: hole 50s cubic-bezier(0.1, 0, 0, 0) infinite;

  @keyframes hole {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(2);
    }

    100% {
      transform: scale(1);
    }
  } ;
`
const BlackHole = styled.div`
  display: relative;

  background: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  box-shadow: inset 0px 0px 85px 0px rgba(0, 0, 0, 0.7);

  @media (max-width: 640px) {
    width: 80px;
    height: 80px;
  }

  @media (min-width: 641px) {
    width: 180px;
    height: 180px;
  }

  @media (min-width: 1024px) {
    width: 180px;
    height: 180px;
  }
`

export default NotFoundAstronaut
