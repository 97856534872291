import React, { useContext } from "react"
import { ThemeContext } from "../../contexts/ThemeContext"
import { H1, H3, H4, LandingButton, Row } from "../../globalStyles"
import { Link } from "gatsby"
import { FullContainerLocal, StarsSix, ColMin } from "./404ComponentStyle"
import NotFoundAstronaut from "./NotFoundAstronaut"
// import { graphql, useStaticQuery } from "gatsby"

function NotFoundComponent({ data }) {
  const { isDarkMode } = useContext(ThemeContext)
  // const data = useStaticQuery(graphql`
  //   {
  //     allContentfulNotFound {
  //       nodes {
  //         svg {
  //           title
  //           file {
  //             url
  //           }
  //           fluid {
  //             ...GatsbyContentfulFluid_withWebp
  //           }
  //         }
  //         para {
  //           para
  //         }
  //         title
  //         buttonText
  //       }
  //     }
  //   }
  // `)

  let main = data.allContentfulNotFound.nodes[0]

  const { para, title, buttonText, svg } = main

  return (
    <FullContainerLocal dark={isDarkMode} className="container-fluid">
      <StarsSix />
      <Row>
        <div className="row justify-content-center   mr-auto text-center align-items-center m-auto">
          <ColMin
            small
            top
            className="col-12 col-md-8 col-lg-7  text-center mr-auto ml-auto
            align-self-center"
          >
            <H1
              landing
              style={{
                //   color: isDarkMode ? buttonBckg.darkHover : "white",
                color: "white",
              }}
            >
              {title}
            </H1>
            <H3
              className="mt-5"
              style={{
                //   color: isDarkMode ? "#505050" : "white"
                color: "white",
              }}
            >
              {para.para}
            </H3>

            <Link to="/">
              <LandingButton
                dark={isDarkMode}
                className="mr-auto  mt-5 ml-auto"
              >
                <H4>{buttonText}</H4>
              </LandingButton>
            </Link>
          </ColMin>
        </div>
        <div className="row justify-content-center mr-auto  ml-auto m-auto">
          <NotFoundAstronaut svg={svg} />
        </div>
      </Row>
    </FullContainerLocal>
  )
}

export default NotFoundComponent
