import React from "react"
import Layout from "../components/Layout"
import NotFoundComponent from "../components/404/404Component"
import useIsClient from "../hooks/rehydration"
// import Loader from "../components/Loader/Loader"
import { graphql, useStaticQuery } from "gatsby"
import HelmetComponent from "../components/Helmet"
function NotFound() {
  const { isClient } = useIsClient()

  const data = useStaticQuery(graphql`
    {
      allContentfulSeoEach(
        sort: { fields: createdAt, order: ASC }
        filter: { node_locale: { eq: "hu" } }
      ) {
        edges {
          node {
            id
            title
            url
            description
            facebookImage {
              fixed {
                srcWebp
              }
            }
          }
        }
      }
      allContentfulNotFound(filter: { node_locale: { eq: "hu" } }) {
        nodes {
          svg {
            title
            file {
              url
            }
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          para {
            para
          }
          title
          buttonText
        }
      }
    }
  `)

  let seo = data.allContentfulSeoEach.edges[0].node
  if (!isClient) return <HelmetComponent seo={seo} />
  return (
    <Layout>
      <HelmetComponent seo={seo} />
      <NotFoundComponent data={data} />
    </Layout>
  )
}

export default NotFound
